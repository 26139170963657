<template>
  <v-data-table :search="search" :headers="headers" :loading="loading" :items="restaurants" >
    <template v-slot:top>
      <v-text-field v-model="search" label="Search a restaurant" prepend-inner-icon="mdi-magnify" />
    </template>
    <template #[`item.published`]="{ item }">
      <v-chip small v-if="!item.published" :ripple="false" outlined><v-icon left small>mdi-close</v-icon>No</v-chip>
      <v-chip small v-else :ripple="false"><v-icon left small>mdi-check</v-icon>Yes</v-chip>
    </template>
    <template v-slot:[`item.manage`]="{ item }">
      <v-btn
        outlined
        text
        rounded
        :to="`/management/restaurants/${item.id}`"
        v-text="'Manage'"
      />
    </template>
  </v-data-table>
</template>

<script>
import firebase from '../../plugins/firebase'
export default {
  name: 'RestaurantManagementTable',

  computed: {
    uid () {
      var user = this.$store.getters['auth/user']
      return user ? user.uid : null
    }
  },

  data () {
    return ({
      loading: false,
      restaurants: [],
      search: null,

      // Table configuration
      headers: [
        {
          text: 'Published',
          value: 'published'
        },
        {
          text: 'Restaurant Name',
          value: 'name'
        },
        {
          text: 'Address',
          value: 'address'
        },
        {
          value: 'manage',
          text: 'Manage',
          filterable: false,
          sortable: false
        }
      ]
    })
  },

  methods: {
    listRestaurants () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('restaurants').where('author', '==', this.uid).get()
          .then(snapshot => {
            resolve(snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data())))
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    // if (!this.uid) {
    //   this.$store.commit('toast/setError', 'You\'re not logged in.')
    //   return
    // }

    this.$data.loading = true

    try {
      this.$data.restaurants = await this.listRestaurants()
    } catch (e) {
      this.$store.commit('toast/setError', 'Failed to get restaurants.')
    }

    this.$data.loading = false
  }
}
</script>
