<template>
  <v-container class="my-5">
    <h2 class="headline">My Restaurants</h2>
    <restaurant-table v-if="userLoggedIn" />
    <v-container fluid class="pa-0 ma-0" fill-height v-else>
      <v-row align="center" justify="center">
        <v-progress-circular indeterminate color="primary" />
      </v-row>
    </v-container>
    <div class="my-3 text-center">
      <v-btn color="accent" to="/management/new-restaurant">New Restaurant</v-btn>
    </div>
  </v-container>
</template>

<script>
import RestaurantTable from '../components/RestaurantManagement/RestaurantTable'

export default {
  name: 'ManageRestaurants',
  components: {
    'restaurant-table': RestaurantTable
  },

  computed: {
    userLoggedIn () {
      return !!this.$store.getters['auth/user']
    }
  }
}
</script>
